/** @format */

import Head from "next/head";
import parse from "html-react-parser";

const SEO = ({
  title,
  description,
  keywords,
  ogImage,
  ogType,
  canonicalURL,
}: any) => {
  return (
    <Head>
      <title>{parse(title)}</title>
      <meta name='description' content={parse(description) as string} />
      <meta name='robots' content='index, follow' />
      <meta name='keywords' content={keywords} />
      <meta property='og:title' content={parse(title) as string} />
      <meta property='og:description' content={parse(description) as string} />
      <meta property='og:image' content={ogImage} />
      <meta property='og:type' content={ogType} />
      <link rel='canonical' href={canonicalURL} />
    </Head>
  );
};
export default SEO;
